<template>
    <section class="login-booking-area mht">
        <div class="container">
            <div class="row">
                <div class="col-lg-2"></div>
                <div class="col-lg-8">
                    <div class="login-booking">
                        <div class="login-content">
                            <div id="tab-1" class="content-tab" style="display: 'block'">
                                <div class="login-form">
                                    <form method="post" accept-charset="utf-8" @submit.prevent="forgotPass()">
                                        <div class="one-half">
                                            <div class="form-email">
                                                <label for="">Enter Registered Email</label>
                                                <input type="text" name="email" id="email" v-model="$v.email.$model" :class="[{custErrorBorder: $v.email.$error}]">
                                                <div class="custError" v-if="$v.email.$error">Field is required</div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        
                                        <div class="one-half">
                                            <div class="btn-submit">
                                                <button type="submit">SUBMIT</button>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>
    </section>
</template>

<script>
import { required, minLength, maxLength, sameAs, email, numeric } from 'vuelidate/lib/validators';
import { request } from "../utils/api";
import {mapGetters} from "vuex";

export default {
    data(){
        return {
            activeMenu: 'login',
            loginError: '',
            forgotError: '',
           email:'',
        }
    },
    computed:{
        ...mapGetters(['isLoggedIn'])
    },
    validations: {
            email: {
                required,
                email
            },
    },
    created(){
        if(this.isLoggedIn){
            this.$router.push('/');
            return;
        }
    },
    methods:{
        async forgotPass(){
            this.$v.$touch();
			if (this.$v.$anyError) {
                return;
            }

            const resp = await request({
                method: 'post',
                url: `/api/v1/user/forgotpassword`,
                data: {
                        email: this.email,
                    }
            })
            
            if (resp.remote === "success") {
                this.$limotoast.success('Email sent to your register email.');
            }else{
                this.$limotoast.error(resp.errors);
            }
        }
    },
}
</script>

<style>

</style>